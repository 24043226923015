import { ChatBadge } from './chat-badge';
import { ChatInput, ChatInputAttachmentButton } from './chat-input';
import { MessageList } from './message';
import { UnreadDot } from './unread-dot';

export const Chat = {
  UnreadDot,
  Badge: ChatBadge,
  Input: ChatInput,
  MessageList,
  AttachmentButton: ChatInputAttachmentButton,
};

export * from './chat-badge';
export * from './chat-empty';
export * from './chat-group';
export * from './chat-tabs';
export * from './chat.path';
export * from './chat.types';
export * from './colleagues-chat-groups/colleagues-chat-groups';
export * from './message/message-divider';
export * from './message/message-list-final-status';
export * from './message/message-skeletons';
export type { MessageVariant } from './message/message-text';
export * from './unread-dot';
export * from './chat-group';
export * from './chat-files-store';
export * from './hooks';
export * from './message/message-media';
