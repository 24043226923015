import { StateMachine } from 'stateless';

export enum ProjectStatus {
  New = 'NEW',
  Accepted = 'ACCEPTED',
  AcceptedOnHold = 'ACCEPTED_ON_HOLD',
  Started = 'STARTED',
  StartedOnHold = 'STARTED_ON_HOLD',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  CancelledByRouting = 'CANCELLED_BY_ROUTING',
  CompletedByRouting = 'COMPLETED_BY_ROUTING',
}

export const completedProjectStatuses = [
  ProjectStatus.Completed,
  ProjectStatus.CompletedByRouting,
];

export const cancelledProjectStatuses = [
  ProjectStatus.Cancelled,
  ProjectStatus.CancelledByRouting,
];

export const finalProjectStatuses = [
  ...completedProjectStatuses,
  ...cancelledProjectStatuses,
];

export const onHoldProjectStatuses = [
  ProjectStatus.AcceptedOnHold,
  ProjectStatus.StartedOnHold,
];

export enum ProjectStatusAction {
  Accept = 'ACCEPT',
  Start = 'START',
  Complete = 'COMPLETE',
  Cancel = 'CANCEL',
  Pause = 'PAUSE',
  Resume = 'RESUME',
}

export function getContractorProjectStateMachine(currentStatus: ProjectStatus) {
  const stateMachine = new StateMachine<ProjectStatus, ProjectStatusAction>(
    currentStatus,
  );

  stateMachine
    .configure(ProjectStatus.New)
    .permit(ProjectStatusAction.Accept, ProjectStatus.Accepted)
    .permit(ProjectStatusAction.Cancel, ProjectStatus.Cancelled);

  stateMachine
    .configure(ProjectStatus.Accepted)
    .permit(ProjectStatusAction.Complete, ProjectStatus.Completed)
    .permit(ProjectStatusAction.Start, ProjectStatus.Started)
    .permit(ProjectStatusAction.Pause, ProjectStatus.AcceptedOnHold)
    .permit(ProjectStatusAction.Cancel, ProjectStatus.Cancelled);

  stateMachine
    .configure(ProjectStatus.AcceptedOnHold)
    .permit(ProjectStatusAction.Resume, ProjectStatus.Accepted)
    .permit(ProjectStatusAction.Complete, ProjectStatus.Completed)
    .permit(ProjectStatusAction.Start, ProjectStatus.Started)
    .permit(ProjectStatusAction.Cancel, ProjectStatus.Cancelled);

  stateMachine
    .configure(ProjectStatus.Started)
    .permit(ProjectStatusAction.Complete, ProjectStatus.Completed)
    .permit(ProjectStatusAction.Pause, ProjectStatus.StartedOnHold)
    .permit(ProjectStatusAction.Cancel, ProjectStatus.Cancelled);

  stateMachine
    .configure(ProjectStatus.StartedOnHold)
    .permit(ProjectStatusAction.Resume, ProjectStatus.Started)
    .permit(ProjectStatusAction.Complete, ProjectStatus.Completed)
    .permit(ProjectStatusAction.Cancel, ProjectStatus.Cancelled);

  return stateMachine;
}

function getAllPermittedTriggers(currentStatus: ProjectStatus) {
  const stateMachine = getContractorProjectStateMachine(currentStatus);

  return stateMachine.getPermittedTriggers();
}

export async function getProjectStatusActionMap(
  currentStatus: ProjectStatus,
): Promise<Map<ProjectStatusAction, ProjectStatus>> {
  const permittedTriggers = await getAllPermittedTriggers(currentStatus);

  return new Map(
    await Promise.all(
      permittedTriggers.map(async (trigger) => {
        const stateMachine = getContractorProjectStateMachine(currentStatus);
        await stateMachine.fire(trigger);
        const nextStatus = stateMachine.state;

        return [trigger, nextStatus] as const;
      }),
    ),
  );
}
