import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringLengthsEnum } from '@proptly/shared';
import {
  commonForm,
  CommonFormProps,
  FormFieldControl,
  SubmitButton,
  TextareaField,
  useSubmitErrorHandler,
} from '@proptly/ui';

import { ProjectRatingFormScoreAlert } from './project-rating-form-score-alert';
import { ProjectRatingRadioGroup } from './project-rating-radio-group';

export interface ProjectRatingFormData {
  overallScore: string;
  comment: string;
}

const defaultValue: ProjectRatingFormData = {
  overallScore: '',
  comment: '',
};

export interface ProjectRatingFormProps
  extends CommonFormProps<ProjectRatingFormData> {}

export const ProjectRatingFormFields = ({
  onSubmit,
  submitButtonContainer,
  isReadOnly,
  defaultValues,
}: ProjectRatingFormProps) => {
  const form = useFormContext<ProjectRatingFormData>();
  const [t] = useTranslation();
  const handleSubmitError = useSubmitErrorHandler(form);

  const { handleSubmit } = form;

  const [isScoreWarning, setIsScoreWarning] = useState(false);

  const handleBeforeSubmit = handleSubmit(async (values) => {
    try {
      if (!values.overallScore) {
        setIsScoreWarning(true);

        return;
      }

      await onSubmit?.(values);
    } catch (error) {
      handleSubmitError(error);
    }
  });

  return (
    <>
      <FormFieldControl name="overallScore" isRequired>
        <ProjectRatingRadioGroup name="overallScore" />
      </FormFieldControl>

      <ProjectRatingFormScoreAlert
        isVisible={isScoreWarning}
        setHide={() => setIsScoreWarning(false)}
      />

      <FormFieldControl name="comment" label={t('additionalComments')}>
        {!isReadOnly && (
          <TextareaField
            minRows={4}
            name="comment"
            maxLength={StringLengthsEnum.CharsLength1000}
          />
        )}
        {isReadOnly && (
          <Text color="grey.dark">
            {defaultValues?.comment ? defaultValues.comment : t('noValue')}
          </Text>
        )}
      </FormFieldControl>

      {!isReadOnly && (
        <SubmitButton
          onClick={handleBeforeSubmit}
          container={submitButtonContainer}
          colorScheme="primary"
          mx="auto"
          size="lg"
          w="full"
        >
          {t('submitFeedback')}
        </SubmitButton>
      )}
    </>
  );
};

export const ProjectRatingForm = commonForm(ProjectRatingFormFields, {
  defaultValue,
});
