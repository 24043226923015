import { useMemo } from 'react';

import { GetPublicMessagesRequest } from '@proptly/chat-shared';

import { useGetPublicChatMessagesInfiniteQuery } from '../api/chat';
import { useOrderChatId, useOrderToken } from './order';

export const useChatMessages = (params?: GetPublicMessagesRequest) => {
  const token = useOrderToken();
  const chatId = useOrderChatId();

  const { data, fetchNextPage, hasNextPage } =
    useGetPublicChatMessagesInfiniteQuery(token, chatId, params);

  const messages = useMemo(() => data?.pages || [], [data?.pages]);

  return {
    messages,
    fetchNextPage,
    hasNextPage,
  };
};
